import { Bill } from '../../../projects/domain/Bill';
import styled from 'styled-components';
import { AtdnText, Colors, FileIcon, spacing } from '@ataden/design-system';
import { useIntl } from 'react-intl';
import { dateMonthAndYearToDateString } from '../../../common/DateUtils';
import { useDownloadBill } from '../../../projects/repositories/ProjectRepository.hook';

type BillLineComponentProps = {
  projectId: string;
  bill: Bill;
};

const Container = styled.div`
    display: grid;
    grid-template-columns: minmax(0px, 1fr) minmax(0px, 1fr) 50px;
    column-gap: 16px;

    width: 100%;
    padding: ${spacing(6)};
    margin-bottom: ${spacing(4)};
    border-bottom: 1px solid ${Colors.Gray100};
`;

const AccessIdItem = styled.div`
    font-family: 'Jetbrains', sans-serif;
    font-size: 18px;
    letter-spacing: 0;
`;

const CreationDateItem = styled.div`
    color: ${Colors.Brown700};
`;

const ActionItem = styled.div`
    text-align: end;
`;

const IconWrapper = styled.div`
    width: 21px;
    cursor: pointer;
`;

export const BillLineComponent = ({ projectId, bill }: BillLineComponentProps) => {

  const intl = useIntl();
  const downloadBill = useDownloadBill();

  const amountLocale = intl.formatMessage({ id: 'common.amount.locale' });
  const formatter = new Intl.NumberFormat(amountLocale, {
    style: 'currency',
    currency: 'EUR',
  });

  const p = intl.formatMessage({ id: 'common.date.month' });
  console.log('p', p);
  const period = dateMonthAndYearToDateString(bill.month, bill.year, p);
  const price = formatter.format(bill.totalCost / 100);

  const onDownload = () => {
    downloadBill(projectId, bill.id)
      .then((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = bill.id + '.pdf';
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch(() => {
        // TODO: handle error
      });
  };

  return (
        <Container>
            <AccessIdItem>
                {period}
            </AccessIdItem>
            <CreationDateItem>
                <AtdnText>
                    {price}
                </AtdnText>
            </CreationDateItem>
            <ActionItem>
                <IconWrapper onClick={onDownload}>
                    <FileIcon width="24px" color={Colors.Brown700}/>
                </IconWrapper>
            </ActionItem>
        </Container>
  );
};
