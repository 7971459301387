import { Bill } from '../../../projects/domain/Bill';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { BillLineComponent } from './BillLineComponent';

type BillListComponentProps = {
  projectId: string;
  bills: Bill[];
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const BillTableHeader = styled.div`
    display: grid;
    grid-template-columns: minmax(0px, 1fr) minmax(0px, 1fr) 50px;
    column-gap: 16px;
    width: 100%;

    padding: ${spacing(6)};
    border-bottom: 1px solid ${Colors.Gray100};
`;

export const BillListComponent = ({ projectId, bills }: BillListComponentProps) => {
  return (
        <Container>
            <BillTableHeader>
                <div>
                    <AtdnText type="important" color={Colors.Brown700}>
                        <FormattedMessage id="billing.invoice.title.period"/>
                    </AtdnText>
                </div>
                <div>
                    <AtdnText type="important" color={Colors.Brown700}>
                        <FormattedMessage id="billing.invoice.title.amount"/>
                    </AtdnText>
                </div>
                <div/>
            </BillTableHeader>

            {bills.map((bill) => (<BillLineComponent key={bill.id} projectId={projectId} bill={bill}/>))}
        </Container>

  );
};
