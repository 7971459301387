import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NoInvoiceYet } from './EmptyInvoice';
import { useGetProjectBills } from '../../../projects/repositories/ProjectRepository.hook';
import { Bill } from '../../../projects/domain/Bill';
import { Loader } from '@ataden/design-system';
import { BillListComponent } from './BillListComponent';

const Container = styled.div`
    margin: 48px 0 96px 0;
`;

type InvoicesComponentProps = {
  projectId: string;
};

export const InvoicesComponent = ({ projectId }: InvoicesComponentProps) => {

  const [bills, updateBills] = useState<Bill[]>([]);
  const [loading, updateLoading] = useState<boolean>(true);

  const getProjectBills = useGetProjectBills();

  useEffect(() => {
    updateLoading(true);
    getProjectBills(projectId)
      .then(updateBills)
      .finally(() => updateLoading(false));
  }, [projectId]);

  if (loading) {
    return (
            <Container>
                <Loader scale={5}/>
            </Container>
    );
  }

  return (
        <Container>
            {bills.length === 0 ? (
                <NoInvoiceYet/>
            ) : (
                <BillListComponent projectId={projectId} bills={bills}/>
            )}
        </Container>
  );
};

