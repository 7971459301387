import { FormattedMessage } from 'react-intl';
import React from 'react';
import { PageDescription, PageHeader, PageLayout } from '../../layout/PageLayout';
import { InvoicesComponent } from './components/InvoicesComponent';
import { BillInformationComponent } from './components/BillInformationComponent';
import { useGetProjectIdFromPath } from '../../AuthenticatedRouter';

export const BillingPage = () => {
  const projectId = useGetProjectIdFromPath();

  return (
        <PageLayout>
            <PageHeader>
                <PageDescription>
                    <FormattedMessage id="billing.invoice.description"/>
                </PageDescription>
            </PageHeader>

            <InvoicesComponent projectId={projectId}/>
            <BillInformationComponent projectId={projectId}/>
        </PageLayout>
  );
};

