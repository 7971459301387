export type Address = {
  line1: string;
  line2: string;
  zipCode: string;
  city: string;
};

export const EMPTY_ADDRESS: Address = {
  line1: '',
  line2: '',
  zipCode: '',
  city: '',
};

export type BillInformation = {
  name: string | undefined;
  address: Address;
  tax: string | undefined;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
};

export const EMPTY_BILL_INFORMATION: BillInformation = {
  name: '',
  address: { ...EMPTY_ADDRESS },
  tax: '',
  contactName: '',
  contactPhone: '',
  contactEmail: '',
};
